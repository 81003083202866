import * as React from 'react'
import { PageProps } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import rectangleButtonLeft from 'assets/icons/rectangle-button-left-2.svg'
import patternIcon from 'assets/icons/pattern-3.svg'
import logo from 'assets/icons/logo-4.svg'
import facebookIcon from 'assets/icons/facebook-2.svg'
import instagramIcon from 'assets/icons/instagram-2.svg'
import twitterIcon from 'assets/icons/twitter-2.svg'
import image404 from 'assets/images/404-img.jpeg'

import CopyrightBar from 'components/layout/Footer/CopyrightBar'
import useBreakpoint from 'hooks/useBreakpoint'

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  max-width: unset;
  margin: 0;
  padding: 0;

  &:before {
    content: '';
    display: block;
  }
`

const LogoWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 100%;
  max-width: 500px;
`

const PatternWrapper = styled.div`
  position: absolute;
  top: -75px;
  left: -350px;

  ${({ theme }) => theme.media.xxl.min} {
    left: -400px;
  }
`

const ButtonLink = styled.a`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`

const ButtonTextLink = styled.a`
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) => theme.media.xl.min} {
    top: -50px;
  }
`

const ImgWrapper = styled.div`
  position: relative;
  width: 75%;
  max-width: 640px;
  margin-top: 80px;
`

const StyledHeading = styled(Heading)`
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
`

const SocialMedias = styled.div`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;

  & > a {
    margin: 0 20px;
  }
`

const NotFoundPage: React.FC<PageProps> = () => {
  const { xl } = useBreakpoint()

  return (
    <Layout hideNav>
      <StyledContainer>
        <SEO title="404: Not found" />
        <LogoWrapper>
          <a href="/">
            <Icon src={logo} alt="logo" auto />
          </a>
        </LogoWrapper>
        <ImgWrapper>
          <img src={image404} alt="404" />
          {!xl && (
            <ButtonTextLink href="/">
              <Text themecolor="dark100">Strona Główna</Text>
            </ButtonTextLink>
          )}
          {xl && (
            <PatternWrapper>
              <Icon src={patternIcon} size={420} alt="pattern" />
              <ButtonLink href="/">
                <Icon src={rectangleButtonLeft} size={100} alt="rectangle" />
              </ButtonLink>
              <ButtonTextLink href="/">
                <Text themecolor="dark100">Strona Główna</Text>
              </ButtonTextLink>
            </PatternWrapper>
          )}
          <StyledHeading size={38} weight={500} themecolor="dark100">
            Nie znaleziono strony
          </StyledHeading>
        </ImgWrapper>
        <CopyrightBar notFoundPage />
      </StyledContainer>
    </Layout>
  )
}

export default NotFoundPage
